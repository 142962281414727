import React from 'react';
import Slider from 'react-slick';


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true
};


export const OpinionsPL = () => {
    return (
    <div className="opinions__wrapper">
    <section id="section5">

        <h1>Opinie</h1>
        <span />
        <Slider {...settings}>
        <div>
            <h1>Apartament nowiutki, czyściutki, świetnie wyposażony i bardzo gustownie urządzony. Jest w nim wszystko co potrzeba a nawet więcej (synek był przeszczęśliwy mając swoje tv z bajkami na wyłączność oraz gry i zabawki , których zapomnieliśmy zabrać z domu). Bardzo dobry kontakt z właścicielką i bezproblemowe przekazanie kluczy. Super lokalizacja, blisko lasu i plaży. Na pewno jeszcze tam wrócimy. Polecam</h1>
            <p>Marzena</p>
        </div>
        <div>
            <h1>Bardzo ładny apartament, super wyposażony, blisko morza. Mili właściciele. Polecam napewno tam wrócimy za rok.</h1>   
            <p>Michał</p>
        </div>
        <div>
            <h1>Obiekt na fajną lokalizację ,do morza jest bardzo blisko,Mieszkanko jest bardzo czyste, zadbane, do dyspozycji cała masa przyborów kuchennych, a właścicielka przemiła.Spędziliśmy tam cudowne 3 dni i myślę że chętnie będziemy tam wracać.</h1>
            <p>Teresa</p>
        </div>
        <div>
            <h1>Podobało nam się wszystko pani uśmiechnięta bardzo miła dla gości pomocna świetny kontakt wszystko w najnowszym stylu w apartamencie było wszystko co potrzebne czystość wzorowa na powitanie miła niespodzianka w postaci podarunku na pewno jeszcze tam wrócimy. </h1>
            <p>Agnieszka</p>
        </div>
        <div>
            <h1>Apartament w pełni urządzony, czysty, ciepły, bardzo stylowe wnętrze. Przemiła pani pomogła dojechać do obiektu i doprowadziła pod same drzwi. Bardzo blisko do sklepu oraz restauracji. Prosta droga do morza. </h1>
            <p>Patrycja</p>
        </div>
        <div>
            <h1>Apartament godny polecenia, czysto, ładnie urządzony, nie spodziewałem się takiego wyposażenia aż się nie chciało wychodzić. Wszędzie blisko, piękna plaża. Mimo trwającej obok budowy było cicho i przyjemnie. Właścicielka też bardzo miła. POLECAM. </h1>
            <p>Krzysztof</p>
        </div>
        <div>
            <h1>Bardzo przytulny i funkcjonalny, nowiutki apartament położony 5 minut od plaży. Bardzo gustownie zaaranżowany, z wszystkimi udogodnieniami. Bardzo starannie dobrane i wykone oświetlenie, co niezbyt często się zdarza - i kameralne i do czytania czy pracy. Bardzo dużo możliwości. Cieplutko, śliczna łazienka z dużą kabiną prysznicową. Bardzo czysto. Parking pod domem. Szczerze polecam.</h1>
            <p>Beata</p>
        </div>
        <div>
            <h1>Całkowite odprężenie od dnia codziennego.</h1>
            <p>Paweł</p>
        </div>
        <div>
            <h1>Atrakcyjna lokalizacja</h1>
            <p></p>
        </div> 
        <div>
            <h1>Plaża, przyroda i zapierające dech w piersiach widoki, przepiękne zachody słońca.</h1>
            <p></p>
        </div>  
      </Slider>
    </section>

      </div>
    )
};

export const OpinionsEN = () => {
    return (
    <div className="opinions__wrapper">
    <section id="section5">

        <h1>Opinions</h1>
        <span />
        <Slider {...settings}>
        <div>
            <h1>The apartment is new, clean, well-equipped and really tastefully furnished. There is everything you need and even more (my son was very happy watching cartoons on his own tv and playing with games and toys that we had forgotten to take from home). Very good contact with the owner and easy transfer of keys. Great location, close to the forest and the beach. We will definitely be back again. I recommend it!</h1>
            <p>Marzena</p>
        </div>
        <div>
            <h1>A very nice apartment, well equipped, near the sea. Nice owners. I recommend it. We'd like to visit it again next year.</h1>   
            <p>Michał</p>
        </div>
        <div>
            <h1>The apartment has a nice location, the sea is very close, the apartment is very clean, neat, a whole bunch of kitchen utensils available, and the owner delightful. We spent there wonderful 3 days and I think that we will gladly come back soon.</h1>
            <p>Teresa</p>
        </div>
   
      </Slider>
    </section>

      </div>
    )
};


export const OpinionsDE = () => {
    return (
    <div className="opinions__wrapper">
    <section id="section5">

        <h1>Meinungen</h1>
        <span />
        <Slider {...settings}>
        <div>
            <h1>Die Wohnung ist gut gelegt, sauber, das Meer ist sehr nah. Das ganze Besteck zur Verfügung. Die Besitzerin ist sehr nett. Wir haben dort schöne 3 Tage verbracht, wir kommen bestimmt wieder.</h1>
            <p>Teresa</p>
        </div>
        <div>
            <h1>Der Appartement ist neu, sauber, toll ausgerichtet und schick ausgerichtet. Dort befindet sich alles und sogar mehr ( der Sohn war sehr glücklich, er hatte eigenen Fernseher mit Kinderkanale und sogar Spielzeug). Wir hatten sehr guten Kontakt mit der Besitzerin, keine Probleme mit Schlüsseln. Lokalisierung ist sehr gut, der Strand und der Wald in der Nähe. Wir fahren dorthin bestimmt wieder.</h1>
            <p>Marzena</p>
        </div>
        <div>
            <h1>Der Appartement ist schön, toll eingerichtet, das Meer in der Nähe. Die Besitzer sehr nett. Wir kommen wieder in der Zukunft. </h1>
            <p>Michał</p>
        </div>
        <div>
            <h1>Uns hat alles gefallen. Die Frau sehr nett, gelächelt, hilfsbereit. Im Appartement ist alles, was nötig ist. Sehr sauber. Zum Willkommen eine schöne Überasschung. Wir kommen dort noch bestimmt wieder. </h1>
            <p>Agnieszka</p>
        </div>
        <div>
            <h1>Der Appartement voll und schön eingerichtet, sauber, warm. Sehr nette Besitzerin ist sehr hilfsbereit. Der Laden und das Restaurant sind in der Nähe. Der gerade Weg zum Meer. </h1>
            <p>Patrycja</p>
        </div>
        <div>
            <h1>Der Appartement ist empfehlenswert, sauber, voll eingerichtet. Ich knute dort wohnen. Alles ist nah, ein schöner Stand. Trocz dem Bau ist es dort lesie und gemütlich. Die Besitzerin ist sehr nett. Ich empfehle alles. </h1>
            <p>Krzysztof</p>
        </div>
        <div>
            <h1>Der Appartement ist gemütlich, neu und praktisch, 5 Minuten vom Strand. Die Beleuchtung in der Wohnung ist gut gemacht, was selten zu treffen ist - gemütlich zum Lesen und zur Arbeit. Sehr viele Möglichkeiten. Schönes Badezimmer mit großer Dusche. Sehr sauber. Der Parkplatz in der Untergrundgarage. Ich empfehle sehr. </h1>
            <p>Beata</p>
        </div>
        <div>
            <h1>Volle Entspannung.</h1>
            <p>Paweł</p>
        </div>
        <div>
            <h1>Gute Lage</h1>
            <p></p>
        </div>
        <div>
            <h1>Ein Strand, wundervolle Landschaften, schöne Sonnenuntergänge.</h1>
            <p></p>
        </div>
   
      </Slider>
    </section>

      </div>
    )
};